@font-face {
  font-family: "Helvetica35Thin";
  src: url("./fonts/Helvetica35Thin.eot");
}

@font-face {
  font-family: "Helvetica65Medium";
  src: url("./fonts/Helvetica65Medium.eot");
}

@font-face {
  font-family: "HelveticaNeueRegular";
  src: url("./fonts/HelveticaNeueBold.otf") format("truetype");
}

@font-face {
  font-family: "Helvetica45Light";
  src: url("./fonts/Helvetica45Light.eot");
}

@font-face {
  font-family: "Helvetica55Roman";
  src: url("./fonts/Helvetica55Roman.eot");
  src: url("./fonts/Helvetica55Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica46LightItalic";
  src: url("./fonts/Helvetica46LightItalic.eot");
  src: url("./fonts/Helvetica46LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "HemiHeadRg-BoldItalic";
  src: url("./fonts/HemiHeadRg-BoldItalic.ttf") format("truetype");
}

.no-outline {
  outline: 0 !important;
}

.card-image {
  width: 100%;
}

.details-image {
  margin: auto;
  max-width: 100%;
}

.details-image-div {
  width: 100%;
  height: 50%;
  display: flex;
}

.content {
  padding-top: 90px;
}

.center-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-bar {
  font-family: "Helvetica35Thin", sans-serif;
  color: #404041 !important;
}

.navigation-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.homepage-event-card {
  background-color: #ecedee !important;
}

.event-card-title-div {
  padding: 20px 20px 0 140px;
  min-height: 140px;
  position: relative;
}

.event-card-title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 100%;
  font-weight: bold;
  color: #a6953f;
}

.event-card-submissionType {
  color: #857732;
}

.event-card-date-location {
  font-family: "HelveticaNeueRegular", sans-serif;
  font-size: 12px;
  color: #58585b;
}

.event-card-detail-button {
  width: 100% !important;
  color: #646668 !important;
  font-family: "HelveticaNeueRegular", sans-serif !important;
  background-color: transparent !important;
  font-weight: bold !important;
  font-size: 11px !important;
  border: 1px solid rgba(189, 168, 49, 0.5) !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
}

.event-card-description {
  font-family: "HelveticaNeueRegular", sans-serif;
  font-size: 14px;
  color: #58585b;
  margin-top: 20px;
  padding: 20px;
}

.event-card-helper-text {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 12px;
  color: #a6a39c;
  position: absolute;
  top: 120px;
  right: 16px;
  bottom: 25px;
  padding-left: 140px;
}

.event-card-helper-text-2 {
  display: none;
}

@media only screen and (max-width: 450px) {
  .event-card-helper-text-2 {
    display: inline-block;
    font-family: "Helvetica35Thin", sans-serif;
    font-size: 12px;
    color: #a6a39c;
    padding: 0 30px;
  }

  .event-card-helper-text {
    display: none;
  }
}

#events {

  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(159, 146, 50, 0.8);
  }
}

.more-button {
  font-family: "Helvetica35Thin", sans-serif !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: #adafb1 !important;
}

.join-insticc-icon {
  height: 40px;
  width: 40px;
}

.homepage-news-title-box {
  padding: 5% 5% 1%;
}

.homepage-news-box {
  padding: 1% 1% 1% 5%;
  margin-right: 5%;
}

.homepage-news-highlight-card {
  width: 100%;
  height: 100%;
  background-color: transparent;
  min-height: 200px;
  box-shadow: 2px 4px 5px 0 #888888 !important;
}

.homepage-news-highlight-image {
  height: 100%;
  width: 100%;
}

.homepage-news-highlight-text {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 100%;
  height: 25%;
  width: 100%;
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  padding: 0 20px;
  text-shadow: 1px 1px black;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);

  // Vertical Center
  display: flex;
  align-items: center;
}

.homepage-news-highlight-bottom-div {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 10px;
  background-color: rgba(157, 141, 110, 0.9);
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homepage-news-highlight-date {
  font-size: 14px;
}

.filter-padding {
  padding-bottom: 50px;
}

.news-card-title {
  font-family: "Helvetica45Light", sans-serif;
  font-size: 14px;
  color: #58585b;
}

.researcher-card-title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 18px;
  color: #928436;
}

.researcher-card-subtitle {
  font-family: "Helvetica45Light", sans-serif;
  font-size: 12px;
  color: #58585b;
}

.researcher-card-description {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 13px;
  color: #58585b;
}

.kl-card-title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 18px;
  color: #a6953f;
}

.kl-card-subtitle {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 14px;
  color: #58585b;
}

.kl-card-description {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 16px;
  color: #58585b;
}

.yellow-text {
  color: #9f9234 !important;
}

.dark-yellow-text {
  color: #807b5e !important;
}

.yellow-bg {
  background-color: rgba(159, 146, 50, 0.2) !important;
}

.information-box {
  width: 200px;
  margin: 0 auto;
}

.information-grid-item {
  padding-top: 75px;
}

.information-title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 20px;
  color: #58585b;
}

.information-contents {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 14px;
  color: #58585b;
}

@media only screen and (max-width: 959px) {
  .information-grid-item {
    padding-top: 25px;
  }
}

.information-logos-grid-item {
  padding-top: 25px;
}

.footer-column-title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.footer-column-item-0 {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 18px;
  padding: 2px 0px;
}

.footer-column-item-1 {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 14px;
  padding: 2px 0px;
}

.footer-column-item-2 {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 12px;
  padding: 2px 10px;
}

.footer-bg {
  background-color: #696a6c;
}

.copyright {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 15px;
  color: #323232;
}

.frame {
  height: 100%;
  /* Equals maximum image height */
  width: 100%;
  text-align: center;
  margin: 1em 0;
}

.img-center-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.multi-slider-img {
  display: block;
  height: 100%;
  margin: auto;
  width: 100%;
}

.yellow-border {
  border: 2px solid #9f9234 !important;
}

.silver-background {
  background-color: silver !important;
}

.transparent-bg {
  background-color: transparent !important;
}

.footer-contacts-bg {
  background-color: #d3d4d6;
}

.priority-box-shadow {
  box-shadow: 10px 1px 10px rgba(0, 0, 0, 0.5);
}

.community-subtitle {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 17px;
  color: #58585b;
}

.community-subtitle2 {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 16px;
  color: #58585b;
}

.community-map-bg {
  background-color: #dbdcde;
}

.community-box-border {
  border: 1px solid #696a6c;
  border-radius: 25px;
}

.community-authors-icon {
  font-size: 40px !important;
  color: #b1b3b5;
}

.community-reviewers-icon {
  font-size: 40px !important;
  color: rgba(136, 160, 192, 0.8);
}

.community-keylecturers-icon {
  font-size: 40px !important;
  color: rgba(157, 147, 91, 0.8);
}

.community-form {
  background-color: rgba(159, 146, 50, 0.2);
  border-radius: 25px;
}

.community-form-text {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 17px;
  color: #a6953f;
}

.community-form-button {
  font-family: "HelveticaNeueRegular", sans-serif !important;
  font-size: 16px !important;
  color: #a6953f !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
  border-color: #a6953f !important;
}

.community-popup-form-label {
  font-family: "HelveticaNeueRegular", sans-serif;
  font-size: 16px;
  color: #9f9232;
}

.community-popup-form-submit {
  background-color: white !important;
  border: 1px solid #9f9232 !important;
  font-family: "HelveticaNeueRegular", sans-serif !important;
  font-size: 17px !important;
  color: #9f9232 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
}

.body-text {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 19px;
  color: #696a6c;
}

.news-details-date {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 17px;
  color: #696a6c;
}

.gallery-slider-div {
  width: 100%;
  margin: 0 auto;
}

.fw-bold {
  font-weight: bold;
}

.researcher-details-subtitle {
  font-family: "Helvetica45Light", sans-serif;
  font-size: 18px;
  color: #4c4c4e;
}

.researcher-slider-title {
  font-family: "Helvetica55Roman", sans-serif;
  font-size: 20px;
  color: #404041;
}

.researcher-slider-subtitle {
  font-family: "Helvetica46LightItalic", sans-serif;
  font-size: 16px;
  color: #4c4c4e;
}

.researcher-details-first-card {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.details .carousel-indicators li {
  background-color: #9f9232;
}

.kl-details-date {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 20px;
  color: #4c4c4e;
}

.kl-details-title {
  font-family: "HelveticaNeueRegular", sans-serif;
  font-size: 24px;
  color: #4c4c4e;
}

.kl-details-event {
  font-family: "HelveticaNeueRegular", sans-serif;
  font-size: 18px;
  color: #4c4c4e;
}

.kl-details-location {
  font-family: "Helvetica45Light", sans-serif;
  font-size: 18px;
  color: #4c4c4e;
}

.partner-subtitle {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 16px;
  color: #696a6c;
}

.partner-card-image {
  max-width: 150px;
}

.partner-details-image {
  max-width: 200px;
}

.homepage-title {
  font-family: "HemiHeadRg-BoldItalic";
  font-size: 34px;
  color: #a6953f;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.gold {
  color: #a6953f;
}

.subtitle {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 19px;
  color: #a6953f;
}

.body-text {
  font-family: "Helvetica35Thin", sans-serif;
  font-size: 17px;
  color: #696a6c;
}

.body-text-title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 17px;
  color: #404041;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.inner-accordion-title {
  font-family: "Helvetica65Medium", sans-serif;
  font-size: 100%;
  color: #a6953f;
  font-weight: bold;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.inner-accordion-title-background {
  background-color: rgba(159, 146, 50, 0.2);
}

.chip {
  padding: 20px 10px !important;
  font-size: 16px !important;
  border-radius: 50px !important;
  width: 100% !important;
  //color: #848588 !important;
  font-family: "HelveticaNeueRegular", sans-serif !important;
}

@media only screen and (max-width: 600px) {
  .ws-pre {
    white-space: pre-line;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chip {
    height: 50px !important;
  }
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.justify-text {
  text-align: justify !important;
  text-justify: inter-word !important;
}

.yellow-link {
  color: #9f9232;
}

.white-link:hover {
  color: #9f9232;
}

.white-link {
  color: white;
}

.paragraph {
  white-space: pre-line;
}

.fs-20 {
  font-size: 16px;
}

.gray-button {
  background-color: #7b7c7e !important;
  color: white !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
}

.lightgray-bg {
  background-color: #ecedee !important;
}

.lightgray-outline-button {
  color: black !important;
  background-color: #e7e7e8 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
  border-color: #7b7c7e !important;
}

.darkgray-outline-button {
  color: white !important;
  background-color: #838383 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 22px 10px !important;
  border-radius: 20px !important;
  border-color: #7b7c7e !important;
}

.white-outline-button {
  color: #7b7c7e !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
  border-color: #7b7c7e !important;
}

.lightgreen-outline-button {
  background-color: #dae6ba !important;
  font-weight: 800 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
  border-color: #7b7c7e !important;
}

.standard-button {
  background-color: #f3f3f3 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
  border-color: #7b7c7e !important;
}

.standard-button:hover {
  background-color: #e9f5f8 !important;
}

.standard-button:active {
  background-color: #737374 !important;
  color: white;
}

.main-box {
  padding: 5%;
}

.accordion-header-card {
  padding: 0 40px 30px;
}

.accordion-content-card {
  padding: 40px 60px;
}

.inner-accordion-content-card {
  padding: 40px 30px 10px;
}

.middle-accordion-grid {
  margin-top: -25px;
  position: absolute;
  z-index: 100;
}

.inner-accordion {
  margin-top: 20px;
}

.center-to-page {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.center {
  display: block;
  margin: auto;
}

.list-image {
  max-width: 100px;
}

.content-editor {
  border: 1px solid gray;
  min-height: 500px;
}

.red-border {
  border: 1px solid red;
  min-height: 500px;
}

.highlightedText-editor {
  border: 1px solid gray;
  min-height: 100px;
}

.round-input {
  border-radius: 100px !important;
}

.department-item {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.black-border {
  border: 1px solid black;
  border-radius: 25px;
}

.dimgray-label {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: normal;
  color: dimgray !important;
}

.blue-label {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: normal;
  color: #7f8ea6 !important;
}

.yellow-label {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: normal;
  color: #aaa073 !important;
}

.yellow-icon {
  font-size: 40px !important;
  color: #aaa073 !important;
}

.yellow-outline-button {
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
  border-color: #b6ad65 !important;
}

.yellow-bg-round {
  background-color: #ece9d6;
  border-radius: 25px;
}

.popup-dialog {
  border-color: #b6ad65;
  border-radius: 25px;
}

.popup-dialog-submit {
  background-color: #cdc493 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
}

.filter-by-text {
  color: #858688;
  text-align: center;
}

@media only screen and (max-width: 1279px) {
  .vertical-hr {
    display: none;
  }

  .gray-vertical-hr {
    display: none;
  }
}

.vertical-hr {
  border-left: 1px solid #a79b2e;
  height: 350px;
  width: 1px;
}

.gray-vertical-hr {
  border-left: 1px solid #b7b9bb;
  height: 350px;
  width: 1px;
}

/* EVENT CARD */
.event-card-calendar-row {
  width: 100%;
  background-color: #e6e2d8;
  min-height: 160px;
}

.calendar-image {
  position: absolute;
  width: 150px;
}

.month {
  z-index: 100;
  position: absolute;
  font-size: 20px;
  padding-left: 55px;
  padding-top: 30px;
}

.year {
  z-index: 100;
  position: absolute;
  font-size: 10px;
  color: white;
  padding-left: 85px;
  padding-top: 50px;
}

.day {
  z-index: 100;
  position: absolute;
  font-size: 40px;
  padding-left: 50px;
  padding-top: 65px;
}

.day-1 {
  padding-left: 60px;
}

.event-container {
  padding: 20px 20px 20px 0;
  background-color: #ededee;
}

.event-content {
  background-color: #ededee;
  padding: 0 20px 8px 20px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.gray-white-button {
  width: 75%;
  color: black !important;
  border-color: black !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 0 50px !important;
  border-radius: 20px !important;
}

/* END OF PARTNER CARD */

.round-image {
  border-radius: 100px;
  width: 150px;
  height: 150px;
}

.gray-login-button {
  border-radius: 50px !important;
  background-color: #e9eaea !important;
}

.gray-search-button {
  height: 60px;
  border-radius: 50px !important;
  background-color: #e9eaea !important;
}

.dimgray-icon {
  padding-left: 10px;
  font-size: 40px !important;
  color: dimgray !important;
}

.login-button {
  background-color: white !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 50px !important;
  border-radius: 20px !important;
  border: 1px solid #9f9234 !important;
  height: 50px;
  font-size: 20px !important;
  color: #9f9234 !important;
}

.profile-round-form-control {
  width: 50%;
}

.profile-round-select {
  width: 50%;
  border-radius: 50px !important;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.my-account-expand-grid {
  margin-top: -30px;
}

.show-box {
  display: block !important;
}

.hide-box {
  display: none !important;
}

.round-select-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #9e9232 50%),
    linear-gradient(135deg, #9e9232 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.round-select-arrow:focus {
  background-image: linear-gradient(45deg, #9e9232 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #9e9232 50%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.full-width-accordion-padding {
  padding: 2% 2% 0;
}

.dropdown-item:focus {
  outline: 0;
}

.dropdown-item:hover {
  background-color: lightgray;
}

@media only screen and (min-width: 1200px) {
  .right-navbar-collapse {
    display: none !important;
  }
}

@media only screen and (max-width: 1199px) {
  .right-navbar {
    display: none !important;
  }
}

.homepage-information-map2 {
  display: none;
}

@media only screen and (max-width: 959px) {
  .homepage-information-map1 {
    display: none;
  }

  .homepage-information-map2 {
    display: block;
  }
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

input[type="file"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-hidden {
  visibility: hidden;
  height: 0;
}

.yellow-hyperlink {
  filter: invert(59%) sepia(8%) saturate(3074%) hue-rotate(13deg) brightness(99%) contrast(80%);
}

.horizontal-flip {
  transform: scaleX(-1);
}

.profile-image-container {
  position: relative;
  width: 150px;
}

.profile-image {
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.profile-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: black;
  border-radius: 100px;
}

.profile-image-placeholder {
  color: white;
  font-size: 12px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.profile-image-container:hover .profile-image-overlay {
  opacity: 0.5;
  cursor: pointer;
}

#profile-image-picker {
  display: none;
}

#profile-image-picker-label {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 30%;
  cursor: pointer;
}

.navbar-profile {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.textarea:hover {
  border: 1px solid #756c27;
}

.textarea:focus {
  border: 2px solid #d1c045;
  outline: none;
}

.result-description * {
  font-family: "Helvetica35Thin", sans-serif !important;
  font-size: 12px !important;
}

.MuiFormHelperText-root {
  margin-top: 0 !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.modal-content {
  position: absolute !important;
  top: 83px !important;
  width: 920px !important;
  margin-left: -150px;
}